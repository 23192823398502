body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}
.ql-picker.ql-font .ql-picker-label[data-value="Noto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Noto"]::before {
  font-family: "Noto Sans Thai", cursive;
  content: "Noto" !important;
}

/* .ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", cursive;
  content: "Roboto" !important;
} */

/* .ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
  font-family: "Raleway", cursive;
  content: "Raleway" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", cursive;
  content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato", cursive;
  content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before {
  font-family: "Rubik", cursive;
  content: "Rubik" !important;
} */

/* Set content font-families */
.ql-font-Noto {
  font-family: "Noto Sans Thai";
}

/* .ql-font-Roboto {
  font-family: "Roboto";
} */
/* .ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Montserrat {
  font-family: "Montserrat";
}
.ql-font-Lato {
  font-family: "Lato";
}
.ql-font-Rubik {
  font-family: "Rubik";
} */

.ql-editor {
  font-family: "Noto Sans Thai";
}
